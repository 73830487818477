import {
  dynamicSort
} from 'lib/utils'

const initialState = {
  loading: false,
  items: null,
  filteredItems: null,
  sortDescending: false,
  sortProperty: null,
  error: false,
  filter: {
    text: '',
    customerGroup: '0',
    salesPerson: '0',
    customerType: '0',
    customerMarket: [],
    showIds: null,
    active: false,
    showFilter: false,
  }
}

const actionPath = 'features/Customers/';

const FETCH_CUSTOMERS = actionPath + 'FETCH_CUSTOMERS';
const FETCH_CUSTOMERS_SUCCESS = actionPath + 'FETCH_CUSTOMERS_SUCCESS';
const FETCH_CUSTOMERS_FAILED = actionPath + 'FETCH_CUSTOMERS_FAILED';

const FILTER_CHANGE = actionPath + 'FILTER_CHANGE';
const FILTER_ITEMS = actionPath + 'FILTER_ITEMS';
const FILTER_RESET = actionPath + 'FILTER_RESET';
const FILTER_SALESPERSON = actionPath + 'FILTER_SALESPERSON';

const SORT_CUSTOMERS = actionPath + 'SORT_CUSTOMERS';

const SHOW_FILTER = actionPath + 'SHOW_FILTER';

export const fetchCustomers = () => {
  return {
    types: [FETCH_CUSTOMERS, FETCH_CUSTOMERS_SUCCESS, FETCH_CUSTOMERS_FAILED],
    callApi: {
      endpoint: 'api/Customers'
    }
  }
}

export const setFilterValue = (name, value) => {
  return {
    type: FILTER_CHANGE,
    name,
    value
  }
}

export const resetFilter = () => {
  return {
    type: FILTER_RESET
  }
}

export const setSalesperson = (name) => {
  return {
    type: FILTER_SALESPERSON,
    value: name
  }
}

export const sortCustomers = (propertyName) => (dispatch, getState) => {
  const {
    items,
    filteredItems,
    sortDescending,
    sortProperty
  } = getState().customers;

  const descending = sortProperty === propertyName ? !sortDescending : false;
  const property = `${descending ? '-' : ''}${propertyName}`;
  const sortedItems = items.slice().sort(dynamicSort(property));
  const sortedFilteredItems = filteredItems ? filteredItems.slice().sort(dynamicSort(property)) : null

  dispatch({
    type: SORT_CUSTOMERS,
    sortDescending: descending,
    sortProperty: propertyName,
    sortedItems,
    sortedFilteredItems
  })
}

export const filterList = (name, value) => (dispatch, getState) => {
  if (name) {
    dispatch({
      type: FILTER_CHANGE,
      name,
      value
    });
  }

  const {
    items,
    filter
  } = getState().customers

  const text = name === 'text' ? value : filter.text;
  const customerType = name === 'customerType' ? value : filter.customerType;
  const salesPerson = name === 'salesPerson' ? value : filter.salesPerson;
  const customerGroup = name === 'customerGroup' ? value : filter.customerGroup
  const customerMarket = name === 'customerMarket' ? value : filter.customerMarket

  const active = text.length > 0 || customerType !== '0' || customerGroup !== '0' || salesPerson !== '0' || customerMarket.length > 0;

  let foundItems = items;

  if (customerType !== '0')
    foundItems = foundItems.filter(i => i.customerTypeId === Number(customerType));

  if (customerGroup !== '0')
    foundItems = foundItems.filter(i => i.customerGroupIds?.includes(Number(customerGroup)))

  if (salesPerson !== '0')
    foundItems = foundItems.filter(i => salesPerson === '-1' ? i.salesPerson === '' : i.salesPerson === salesPerson);

  if (customerMarket.length)
    foundItems = foundItems.filter(i => i.customerMarketTypes?.some(r => customerMarket.indexOf(r) >= 0))

  // Filter Texts
  const number = parseInt(text.replace(/\s/g, ''))
  if (!isNaN(number)) {
    //CustomerId
    foundItems = foundItems.filter(i => i.id.toString().indexOf(number) === 0);
  } else if (text.length) {
    //Fritext
    let searchValue = text.toLowerCase();
    foundItems = foundItems.filter(i => i.name.toLowerCase().indexOf(searchValue) >= 0
      || i.address.name.toLowerCase().indexOf(searchValue) >= 0
      || i.address.address1.toLowerCase().indexOf(searchValue) >= 0
      || i.address.zipCode.toLowerCase().indexOf(searchValue) >= 0
      || i.address.city.toLowerCase().indexOf(searchValue) >= 0)
  }

  dispatch({
    type: FILTER_ITEMS,
    items: foundItems.length ? foundItems : null,
    active
  })
}

export const showFilter = (show) => {
  return {
    type: SHOW_FILTER,
    show
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case FETCH_CUSTOMERS:
      return {
        ...state,
        loading: true,
        items: null,
        error: false
      }

    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }

    case FETCH_CUSTOMERS_FAILED:
      return {
        ...state,
        loading: false,
        items: [],
        error: true
      }

    case FILTER_CHANGE:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.name]: action.value
        }
      }

    case FILTER_SALESPERSON:
      return {
        ...state,
        filter: {
          ...state.filter,
          salesPerson: action.value,
          active: true
        }
      }

    case FILTER_ITEMS:
      return {
        ...state,
        filteredItems: action.items,
        filter: {
          ...state.filter,
          active: action.active
        }
      }


    case FILTER_RESET:
      return {
        ...state,
        filter: {
          ...state.filter,
          text: '',
          salesPerson: '0',
          customerGroup: '0',
          customerType: '0',
          customerMarket: [],
          showIds: null,
          active: false
        }
      }

    case SORT_CUSTOMERS:
      return {
        ...state,
        sortDescending: action.sortDescending,
        sortProperty: action.sortProperty,
        items: action.sortedItems,
        filteredItems: action.sortedFilteredItems,
      }

    case SHOW_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          showFilter: action.show
        }
      }

    default:
      return state
  }
}
