import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux'
import store from 'redux/store'

import 'normalize.css'
import 'sass/flexboxgrid.scss'
import 'sass/base.scss';

import App from './features/App/App';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:'https://29be42809d8d4379bac5c4488f54522f@o558011.ingest.sentry.io/5693633',
    integrations: [new Integrations.BrowserTracing()],
    normalizeDepth: 10,
    //debug: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Sentry.ErrorBoundary>
        <App />
      </Sentry.ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
